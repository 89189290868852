exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-intern-js": () => import("./../../../src/pages/it-intern.js" /* webpackChunkName: "component---src-pages-it-intern-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-news-and-insights-js": () => import("./../../../src/pages/news-and-insights.js" /* webpackChunkName: "component---src-pages-news-and-insights-js" */),
  "component---src-pages-our-solutions-js": () => import("./../../../src/pages/our-solutions.js" /* webpackChunkName: "component---src-pages-our-solutions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-briefs-js": () => import("./../../../src/pages/product_briefs.js" /* webpackChunkName: "component---src-pages-product-briefs-js" */),
  "component---src-pages-public-founds-js": () => import("./../../../src/pages/public-founds.js" /* webpackChunkName: "component---src-pages-public-founds-js" */),
  "component---src-pages-questionnaire-js": () => import("./../../../src/pages/questionnaire.js" /* webpackChunkName: "component---src-pages-questionnaire-js" */),
  "component---src-templates-article-v-2-js": () => import("./../../../src/templates/article_v2.js" /* webpackChunkName: "component---src-templates-article-v-2-js" */),
  "component---src-templates-blog-api-js": () => import("./../../../src/templates/blog-api.js" /* webpackChunkName: "component---src-templates-blog-api-js" */)
}

